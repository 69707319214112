
/*=============================================
=            category            =
=============================================*/
.category-wrapper {
  .col-12 {
    .single-category {
      margin-bottom: 20px;
    }
    &:last-child {
      .single-category {
        margin-bottom: 0;
      }
    }
  }
}

.single-category {
  display: flex;
  align-items: center;

  padding: 20px 30px;

  border-radius: 5px;
  background-color: $white;
  background-position: center right;
  box-shadow: 0 0 5px rgba($black, 0.16);
  &__image {
    flex-basis: 35px;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;

    margin-left: 10px;
  }
  &:hover {
    .single-category__title {
      text-decoration: underline;
    }
  }
}

/*=====  End of category  ======*/

