/* 3rd party styles */
@import "~bootstrap/scss/bootstrap";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";

/* project styles */

@import "1-base/variables";
@import "2-global/default";
@import "2-global/common";
@import "2-global/helper";
@import "2-global/spacing";
@import "3-layouts/header";
@import "3-layouts/footer";
@import "4-elements/button";
@import "4-elements/section-title";
@import "4-elements/backgrounds";
@import "4-elements/breadcrumb";
@import "4-elements/preloader";
@import "4-elements/no-items-found";
@import "5-sections/hero-slider";
@import "5-sections/category-slider";
@import "5-sections/product";
@import "5-sections/contact-content";
@import "5-sections/profile";
@import "5-sections/auth";
@import "5-sections/welcome";
@import "5-sections/notification";
@import "5-sections/chat";
@import "5-sections/cart";
@import "5-sections/checkout";
@import "5-sections/banner";
@import "5-sections/category";
